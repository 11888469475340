<template>
    <!--    广告客户管理-->
    <div class="Customer" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="客户名称/联系人/手机号" v-model="form.search" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.advert.customer.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.advert.customer.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.advert.customer.export')"
                >导出
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
            >
                <el-table-column label="序号" width="50" type="index" fixed="left" />
                <el-table-column label="编码" width="200" prop="code" />
                <el-table-column label="客户名称" width="120" prop="name" />
                <el-table-column label="联系人" width="100" prop="linkman" />
                <el-table-column label="手机号" width="120" prop="mobile" />
                <el-table-column label="地址" width="160" prop="address" />
                <el-table-column label="投放数量" width="80" prop="totalNum" />
                <el-table-column label="启用广告数量" width="100" prop="onNum" />
                <el-table-column label="停用广告数量" width="100" prop="offNum" />
                <el-table-column label="创建人" width="120" prop="creator" />
                <el-table-column label="创建时间" width="160" prop="createTime" />
                <el-table-column label="操作" min-width="140" header-align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.advert.customer.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.advert.customer.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import EfStartDate from '../../components/EfStartDate';
import EfEndDate from '../../components/EfEndDate';

export default {
    name: 'Customer',
    components: { EfEndDate, EfStartDate },
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/advert/customer/page',
                delete: '/advert/customer/delete',
            },
        };
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleCreate() {
            Util.nameJump(this, 'menu.advert.customer.create', ['广告管理', '广告客户管理', '批量创建客户信息']);
        },
        handleExport() {
            UrlUtils.Export(this, '广告客户管理', '/advert/customer/export', this.form);
        },

        handleDelete(code) {
            UrlUtils.DeleteRemote(this, this.url.delete, code);
        },
        handleEdit(row) {
            Util.nameJump(this, 'menu.advert.customer.edit', ['广告管理', '广告客户管理', '编辑客户信息'], {
                form: row,
                code: row.code,
            });
        },
    },
};
</script>

<style scoped>
.Customer .el-form-item {
    margin-bottom: 0;
}
</style>
